export interface IDocument {
  tipo: string
  numero: string
  data_emissao: string
  emissor: string
  uf: string
}

export enum DOCUMENT_STATUS {
  PENDING_ANALYSIS = 'pendente-analise',
  APPROVED = 'aprovado',
  DENIED = 'negado',
}
