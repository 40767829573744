import React, { SyntheticEvent, useState } from 'react'
import { TextField, InputAdornment } from '@material-ui/core'
import { RemoveRedEye, VisibilityOffOutlined } from '@material-ui/icons'
import { FieldRenderProps } from 'react-final-form'
import { capitalize } from 'helpers'
import { useErrors } from 'hooks'

import CurrencyInput from '../CurrencyInput'

interface IInput extends FieldRenderProps<any, HTMLElement> {
  label: string
  errorText: string
  inputType: 'text' | 'password' | 'textarea'
  size: 'small' | 'medium'
  isCurrency?: boolean
  withError?: boolean
}
const Input = ({
  id,
  input,
  meta,
  inputType,
  disabled,
  className,
  inputMode,
  tabIndex,
  inputRef,
  onChange: onChangeInput,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  onPaste,
  onBlur: onBlurInput,
  autoComplete,
  label,
  size,
  variant,
  error,
  helperText,
  disableHelperText,
  isCurrency,
  placeholder,
  InputProps,
  inputProps,
  withError,
}: IInput) => {
  const { name, value, onClick, onChange, onFocus, onBlur } = input
  const { error: metaError, touched, submitError, submitFailed } = meta
  const [showPassword, setShowPassword] = useState<boolean>()
  const { getFieldError } = useErrors()
  const fieldError = getFieldError(name)
  const formError = metaError || fieldError || submitError || ''
  let CustomInputProps = InputProps

  if (isCurrency) {
    CustomInputProps = { ...InputProps, inputComponent: CurrencyInput as any }
  } else if (inputType === 'password') {
    const Icon = showPassword ? VisibilityOffOutlined : RemoveRedEye
    CustomInputProps = {
      ...InputProps,
      endAdornment: (
        <InputAdornment position='end'>
          <Icon
            className='cursor-pointer'
            color='secondary'
            onClick={() => setShowPassword(!showPassword)}
          />
        </InputAdornment>
      ),
    }
  }

  const isTextArea = inputType === 'textarea'

  return (
    <>
      <TextField
        type={
          isTextArea ? 'textarea' : inputType === 'password' && showPassword ? 'text' : inputType
        }
        id={id}
        disabled={disabled}
        name={name}
        onClick={onClick}
        onChange={(e: SyntheticEvent) => {
          onChange(e)
          onChangeInput && onChangeInput(e)
        }}
        onBlur={onBlur || onBlurInput}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onPaste={onPaste}
        value={value === undefined ? '' : value}
        label={label}
        className={className}
        inputMode={inputMode}
        tabIndex={tabIndex}
        ref={inputRef}
        autoComplete={autoComplete}
        helperText={
          !disableHelperText && ((touched && capitalize(formError)) || helperText || error)
        }
        error={
          Boolean((touched || submitFailed) && formError) || Boolean(error) || Boolean(withError)
        }
        size={size || 'small'}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={CustomInputProps}
        inputProps={inputProps}
        variant={variant || 'standard'}
        fullWidth
      />
    </>
  )
}

export default Input
