import { SALESCHANNEL_SUBMODULES } from 'interfaces/submodules'

// PATHS de referencia para a api
// É necessário deixar o mesmo padrão de nomes em modulos diferentes como por exemplo: CP/CDC - PROPOSAL_ID
// com isso, em paginas utilizadas em módulos diferentes(CP/CDC), é possível usar o hook da api SUBMODULES_PATH
// Através desse hook, a página sempre chama a api que ela quer(PROPOSAL_ID) e ele define com base no módulo qual path
// deve ser utilizado

export const PATHS: { [key: string]: any } = {
  LOGIN: '/login',
  RECOVERY_PASSWORD_TOKEN: '/login/esqueci-minha-senha/enviar',
  RECOVERY_PASSWORD_TOKEN_UPDATE: '/login/esqueci-minha-senha/atualizar',
  ME: '/me/',
  ME_CONFIRM_TOKEN: '/me/confirmar-contato/',
  ME_CHANGE_PASSWORD: '/me/alterar-senha/',
  ME_FUNCTIONS: '/me/funcoes/',
  ME_CONFIRM_CONTACT: '/me/confirmar-contato',
  ME_SEND_CONFIRM_CONTACT: '/me/enviar-confirmar-contato',
  ME_USER_TERMS: '/me/assinar-termo-de-uso',
  SEND_CONFIRM_CONTACT: '/login/enviar-confirmar-contato/',
  FIRST_ACCESS: '/primeiro-acesso',
  CONFIRM_TOKEN: '/login/confirmar-contato/',
  SEND_TOKEN: '/login/enviar-token/',
  REFRESH_TOKEN: '/login/atualizar-token/',
  EMPLOYEE: '/cep/funcionarios/',
  EMPLOYEE_ID: '/cep/funcionarios/:id/',
  INCOME_TAX_PDF: '/cep/funcionarios/:id/info-ir-pdf/',
  BLOCK_EMPLOYEE_BY_ID: '/cep/funcionarios/:id/bloqueio',
  DEMISSION_ID: '/cep/funcionarios/:id/demitir',
  EMPLOYEE_DEMISSION: '/cep/funcionarios/:id/demitir/',
  EMPLOYEE_BULK: '/cep/funcionarios/bulk/',
  IMPORT_EMPLOYEE_CSV: '/cep/funcionarios/csv/',
  SEND_EMAIL_DEMISSION_EMPLOYEE_CSV: '/cep/funcionarios/demitir/gera-demissao',
  DELETE_LOTE_EMPLOYEE_CSV: '/cep/funcionarios/demitir/csv',
  EMPLOYEE_CSV: '/cep/funcionarios/relatorio/csv/',
  EMPLOYEE_CONFIRM: '/cep/funcionarios/validar/',
  USER_FUNCTION: '/me/funcoes/:userFunction/',
  CONTRACT: '/cep/contratos/',
  CONTRACT_CSV: '/cep/contratos/csv/',
  PROPOSAL_CSV: '/cep/propostas/csv/',
  CONTRACT_ID: '/cep/contratos/:id',
  CONTRACT_HISTORIC: '/credito/historicos_contratos/:id',
  CONTRACT_PAYMENTS: '/credito/historicos_pagamentos/:id',
  CEP_COMPANIES: '/cep/empresas',
  CEP_COMPANIES_EMPLOYEE_INFOS: '/cep/empresas/empregos',
  CEP_COMPANIES_CONTRACTS_INFOS: '/cep/empresas/contratos',
  CEP_COMPANIES_REPASS_INFOS: '/cep/empresas/lotes',
  RECEIVABLE: '/cep/recebiveis/',
  RECEIVABLES_ID: '/cep/recebiveis/:id/',
  AVERBATION_APPROVED: '/cep/contratos/averbar/',
  AVERBATION_DENIED: '/cep/contratos/negar-averbacao/',
  COVENANT: '/cep/convenios/',
  COVENANT_ID: '/cep/convenios/:id/',
  LOT: '/cep/lotes/',
  LOT_CSV: '/cep/lotes/csv/',
  LOT_ID_GET: '/cep/lotes/:id/detalhes',
  LOT_ID_BULK_PATCH: '/cep/lotes/:id/detalhes/bulk/',
  LOT_ID: '/cep/lotes/:id/',
  LOT_SUBMIT: '/cep/lotes/:id/submeter/',
  USER: '/usuarios/',
  USER_ID: '/usuarios/:id/',
  USER_PERMISSION_DELETE: '/usuarios/:usuarioCpf/',
  SIMULATE_CREDIT: '/cep/credito/simular/',
  SIMULATE_PORTABILITY: '/cep/portabilidades/simular/',
  REQUEST_CREDIT: '/cep/credito/solicitar/',
  RENEGOTIATE_SIMULATE: '/cep/contratos/renegociar/simular/',
  RENEGOTIATE: '/cep/contratos/renegociar/solicitar/',
  PROPOSALS: '/cep/propostas/',
  PROPOSAL_ID: '/cep/propostas/:id/',
  PORTABILITIES: '/cep/portabilidades/',
  PORTABILITY_ID: '/cep/portabilidades/:id',
  APPROVE_PORTABILITY: '/cep/portabilidades/:id/averbar/',
  REQUEST_PORTABILITY: '/cep/portabilidades/solicitar/',
  SIGN_PORTABILITY: '/cep/portabilidades/:id/assinar',
  SIGN_CONTRACT: '/cep/contratos/:id/assinar',
  SEND_INVITE: '/usuarios/:id/enviar-convite',
  GENERATE_BILLET: '/cobranca/boletos/',
  GENERATE_BILLET_ANTECIPATION: '/cobranca/boletos/antecipar',
  SEND_BILLET: '/cobranca/boletos/:id/enviar/',
  GENERATE_BILLET_SALESOFF: '/cobranca/boletos/liquidar/',
  SEND_BILLET_SALESOFF: '/cobranca/boletos/liquidar/:id/enviar/',
  TRANSFER_EMPLOYEE_SUPERVISOR: '/cep/funcionarios/:cpf/:cnpj/transferir/',
  TRANSFER_EMPLOYEE: '/cep/funcionarios/:cpf/transferir/',
  LGPD_SEND_TOKEN: '/cep/cadastro-lgpd/enviar-token/',
  LGPD_VALIDATE: '/cep/cadastro-lgpd/validar-token/',
  LGPD_GET_EMPLOYEES: '/cep/cadastro-lgpd/',
  LGPD_GET_EMPLOYEE: '/cep/cadastro-lgpd/:id/',
  LGPD_APPROVE_EMPLOYEES: '/cep/cadastro-lgpd/aprovar/',
  LGPD_DENY_EMPLOYEES: '/cep/cadastro-lgpd/rejeitar/',
  LGPD_GENERATE_PDF: '/cep/cadastro-lgpd/emitir-pdf/:id',
  VERIFY_LOGIN_STATUS: '/login/situacao-cadastro',
  VERIFY_LOGIN: '/login/verificar-cadastro',
  PERMISSIONS: '/permissoes/info/entidade/',
  GENERATE_SIGN_CEP: '/cep/contratos/:id/link-assinatura/',
  PORTABILITY_GENERATE_SIGN_CEP: '/cep/portabilidades/:id/link-assinatura',
  REACTIVATE: '/cep/funcionarios/:id/reverter-demissao',
  PERSON: '/pessoas/:id',
  CONFIRM_REGISTER: '/login/confirmar-cadastro/',
  CONFIRM_REGISTER_FGTS: '/cp/clientes/fgts/cadastro/',
  REGISTER_CP_FGTS: '/cp/clientes/fgts/confirmar-cadastro/',
  [SALESCHANNEL_SUBMODULES.CP]: {
    CONTRACTS: '/cp/contratos/',
    CONTRACT_ID: '/cp/contratos/:id',
    RECEIVABLE: '/cp/recebiveis/',
    APPROVE_DOCUMENT: '/cp/clientes/documento-comprovacao/:id/aprovar/',
    RESEND_DOCUMENT: '/cp/clientes/documento-comprovacao/proposta/:id/reenviar/',
    RESEND_UNIQ_DOCUMENT: '/cp/clientes/documento-comprovacao/reenviar/:id',
    SIMULATE_FGTS: '/cp/credito/garantia-fgts/simular/',
    REQUEST_FGTS: '/cp/credito/garantia-fgts/solicitar/',
    REQUEST_FGTS_PAYMENTS: '/personalizacoes/pefisa/interfaces/:cpf/contas/',
    FGTS_CAIXA: '/cp/credito/:id/consultar-caixa',
    SIGN_CONTRACT: '/cp/contratos/:id/assinar/',
    RENEGOTIATE_SIMULATE: '/cp/contratos/renegociar/simular/',
    RENEGOTIATE: '/cp/contratos/renegociar/solicitar/',
    PROPOSAL_ID: '/cp/propostas/:id/',
    SEND_DOCUMENTS: '/cp/clientes/documento-comprovacao/',
    SALES_CHANNEL_ID: '/cp/convenios/',
    RESEND_PENDING_SIGN_MAIL: '/cp/contratos/:id/reenviar-assinatura',
    PROPOSALS: '/cp/propostas/',
    SIMULATE_CREDIT: '/cp/credito/simular/',
    REQUEST_CREDIT: '/cp/credito/solicitar/',
    CLIENT_ID: '/cp/clientes/:id/',
    CLIENT: '/cp/clientes/',
    CANCELEDBOOKING: '/cp/credito/:cpf/cancelar-contrato-proposta-fgts',
    GENERATE_CSV: '/usuarios/list-csv/',
  },
  [SALESCHANNEL_SUBMODULES.CDC]: {
    CONTRACTS: '/cdc/contratos/',
    CONTRACT_ID: '/cdc/contratos/:id',
    RECEIVABLE: '/cdc/recebiveis/',
    APPROVE_DOCUMENT: '/cdc/clientes/documento-comprovacao/:id/aprovar/',
    RESEND_DOCUMENT: '/cdc/clientes/documento-comprovacao/proposta/:id/reenviar/',
    RESEND_UNIQ_DOCUMENT: '/cdc/clientes/documento-comprovacao/reenviar/:id',
    SIMULATE_FGTS: '/cdc/credito/garantia-fgts/simular/',
    SIGN_CONTRACT: '/cdc/contratos/:id/assinar/',
    RENEGOTIATE_SIMULATE: '/cdc/contratos/renegociar/simular/',
    RENEGOTIATE: '/cdc/contratos/renegociar/solicitar/',
    PROPOSAL_ID: '/cdc/propostas/:id/',
    SEND_DOCUMENTS: '/cdc/clientes/documento-comprovacao/',
    SALES_CHANNEL_ID: '/cdc/convenios/',
    RESEND_PENDING_SIGN_MAIL: '/cdc/contratos/:id/reenviar-assinatura',
    PROPOSALS: '/cdc/propostas/',
    SIMULATE_CREDIT: '/cdc/credito/simular/',
    REQUEST_CREDIT: '/cdc/credito/solicitar/',
    CLIENT_ID: '/cdc/clientes/:id/',
    CLIENT: '/cdc/clientes/',
    METADATA: '/propostas/atualizar_metadados/:id',
  },
  [SALESCHANNEL_SUBMODULES.CDV]: {
    CONTRACTS: '/cdc-veiculo/contratos/',
    CONTRACT_ID: '/cdc-veiculo/contratos/:id',
    RECEIVABLE: '/cdc-veiculo/recebiveis/',
    APPROVE_DOCUMENT: '/cdc-veiculo/clientes/documento-comprovacao/:id/aprovar/',
    RESEND_DOCUMENT: '/cdc-veiculo/clientes/documento-comprovacao/proposta/:id/reenviar/',
    RESEND_UNIQ_DOCUMENT: '/cdc-veiculo/clientes/documento-comprovacao/reenviar/:id',
    SIGN_CONTRACT: '/cdc-veiculo/contratos/:id/assinar/',
    RENEGOTIATE_SIMULATE: '/cdc-veiculo/contratos/renegociar/simular/',
    RENEGOTIATE: '/cdc-veiculo/contratos/renegociar/solicitar/',
    PROPOSAL_ID: '/cdc-veiculo/propostas/:id/',
    SEND_DOCUMENTS: '/cdc-veiculo/clientes/documento-comprovacao/',
    SALES_CHANNEL_ID: '/cdc-veiculo/convenios/',
    RESEND_PENDING_SIGN_MAIL: '/cdc-veiculo/contratos/:id/reenviar-assinatura',
    PROPOSALS: '/cdc-veiculo/propostas/',
    SIMULATE_CREDIT: '/cdc-veiculo/credito/simular/',
    REQUEST_CREDIT: '/cdc-veiculo/credito/solicitar/',
    CLIENT_ID: '/cdc-veiculo/clientes/:id/',
    CLIENT: '/cdc-veiculo/clientes/',
    FIPE_TABLE: '/cdc-veiculo/fipe/',
    FIPE_TABLE_MARCA: '/cdc-veiculo/fipe/marcas',
    FIPE_TABLE_MODELS: '/cdc-veiculo/fipe/modelos',
    CLIENT_PJ: '/cdc-veiculo/pj/clientes/',
    CLIENT_ID_PJ: '/cdc-veiculo/pj/clientes/:id/',
    SIMULATE_CREDIT_PJ: '/cdc-veiculo/pj/credito/simular/',
    REQUEST_CREDIT_PJ: '/cdc-veiculo/pj/credito/solicitar/',
    SIGN_CONTRACT_PJ: '/cdc-veiculo/pj/contratos/:id/assinar/',
    SIMULATE_RENEGOTIATE_CREDIT_PJ: '/cdc-veiculo/pj/renegociar/simular/',
    REQUEST_RENEGOTIATE_CREDIT_PJ: '/cdc-veiculo/pj/renegociar/solicitar/',
  },
  [SALESCHANNEL_SUBMODULES.PJ]: {
    CONTRACTS: '/pj/contratos/',
    CONTRACT_ID: '/pj/contratos/:id',
    RECEIVABLE: '/pj/recebiveis/',
    APPROVE_DOCUMENT: '/pj/clientes/documento-comprovacao/:id/aprovar/',
    // @TODO: Removido, pois, não tem envio de documentação para PJ, por agora
    // RESEND_DOCUMENT: '/pj/clientes/documento-comprovacao/proposta/:id/reenviar/',
    // RESEND_UNIQ_DOCUMENT: '/pj/clientes/documento-comprovacao/reenviar/:id',
    SIGN_CONTRACT: '/pj/contratos/:id/assinar/',
    RENEGOTIATE_SIMULATE: '/pj/contratos/renegociar/simular/',
    RENEGOTIATE: '/pj/contratos/renegociar/solicitar/',
    PROPOSAL_ID: '/pj/propostas/:id/',
    SEND_DOCUMENTS: '/pj/clientes/documento-comprovacao/',
    SALES_CHANNEL_ID: '/pj/convenios/',
    RESEND_PENDING_SIGN_MAIL: '/pj/contratos/:id/reenviar-assinatura',
    PROPOSALS: '/pj/propostas/',
    CLIENT_ID: '/pj/empresas/:id/',
    CLIENT: '/pj/empresas/',
    METADATA: '/propostas/atualizar_metadados/:id',
    SIMULATE_CREDIT_DUPLICATE: '/pj/credito/descontos-duplicatas/simular',
    REQUEST_CREDIT_DUPLICATE: '/pj/credito/descontos-duplicatas/solicitar',
    SIMULATE_CREDIT_WOKRING_CAPITAL: '/pj/credito/simular',
    REQUEST_CREDIT_WOKRING_CAPITAL: '/pj/credito/solicitar',
  },
  CORBAN: {
    SIMULATE_CREDIT: '/corbans/consignado/credito/simular',
    CORBAN_ID: '/corbans/:id',
    CORBAN: '/corbans/',
    USERS: '/corbans/usuarios',
    OPERATORS: '/corbans/:id/operadores',
    OPERATORS_ID: '/corbans/:cnpj/operadores/:id',
    REQUEST_CREDIT: '/corbans/consignado/credito/solicitar',
    CHANNELS: '/corbans/canais',
    ASSOCIATE_SUBCORBAN: '/corbans/:id/associar/:canalId',
    UPDATE_SUBCORBAN: '/corbans/:id/associar/:canalId/alterar/',
    RENEGOTIATE_SIMULATE: '/corbans/consignado/renegociar/simular',
    RENEGOTIATE: '/corbans/consignado/renegociar/solicitar',
    PORTABILITY_SIMULATE: '/corbans/consignado/portabilidade/simular',
    REQUEST_PORTABILITY: '/corbans/consignado/portabilidade/solicitar',
    SEND_PHONE_TOKEN: '/corbans/consignado/token/enviar/',
    VALIDATE_PHONE_TOKEN: '/corbans/consignado/token/validar/',
    LGPD_EMPLOYEE: '/corbans/consignado/enviar-convite-funcionario/',
    REMOVE_OPERATORS: '/corbans/:corbanid/gestores/:usuarioid/desabilitar',
    VALIDATE_TOKEN_ASSOCIATE_CORBAN: '/corbans/:corbanid/associar/:canalid/detalhar',
    EMPLOYEE_CONFIRM: '/corbans/consignado/validar-funcionario/',
  },
}

export default PATHS
